import React from "react"
import Header from "../components/header"
import Layout from "../components/layout"

const Index = ({ data, location }) => {
  const siteTitle = 'witcraft.io'//data.site.siteMetadata.title

  return (
    <>
    <Header page='/' />
    <Layout location={location} title={siteTitle}>
    <div className="container sections-wrapper">
        <div className="row">
            <div className="primary col-lg-8 col-12">
                <section className="about section">
                    <div className="section-inner shadow-sm rounded">
                        <h2 className="heading">About Me</h2>
                        <div className="content">
                            <p>
                              My name is Dean Witcraft and I am a software engineer and web developer. 
                              I live near Folsom, California with my wife Mary. I've been writing business 
                              software professionally for more than 20 years, most recently concentrating on 
                              fullstack corporate and internal business web applications. Things I like: playing 
                              my guitar, gaming, geneology and backyard barbeques with my wife and adult kids. 
                              When I can get away, I enjoy SCUBA diving and exploring the Sierras. 
                            </p>
                            <p></p>
                            {/*todo comment this out until I write the about mepage -- 
                              Read more than you probably wanted to know on the About Me page. 
                              */}
                            <p>Find out about my experience and view some recommendations on&nbsp; 
                              <a style={{textDecoration: "underline"}} href="https://www.linkedin.com/in/deanwitcraft/">LinkedIn</a>
                            </p>
                        </div>
                    </div>                 
                </section>
    
               <section className="latest section">
                    <div className="section-inner shadow-sm rounded">
                        <h2 className="heading">Latest Project</h2>
                        <div className="content">    
                            <div className="item featured text-center">
                                <div className="featured-image">
                                    <a href="https://codestaff.io" rel="noreferrer" target="_blank">
                                    {/*Framed image */}
                                    <img style={{
                                            border: '1px solid #ccc',
                                            background: '#eee',
                                            margin: 'auto',
                                            padding: '15px 10px' }}
                                        className={'img-fluid float-left'}
                                        src={require(`../images/csio-homepage.png`)}
                                        alt="codestaff.io"
                                    />
                                    </a>

                                </div>
                                
                                <h3 className="title mb-3">
                                    <p>JAMstack with Gatsby, and full READ/WRITE DATABASE support using AWS lambda functions.</p>
                                    <hr />
                                </h3>
                                    
                                <div className="desc text-left">                                    
                                    <p>JAMstack is not just for simple static sites. It can fully support business functionality normally
                                        requirng a backend server while providing the loading speed and improved security of static pages.
                                    </p>
                                </div>
                                   
                                <a className="btn btn-cta-secondary" href="https://codestaff.io" rel="noreferrer" target="_blank">
                                    <i className="fas fa-thumbs-up"></i>Visit Codestaff
                                </a>
                                <div className="desc text-center" style={{marginTop: '2em'}}>                                    
                                    <p>This site is continously under development as the owner devlops new ideas.</p>
                                </div>                       
                            </div>
                         </div>  
                    </div>                
                </section>
                <section className="experience section">
                    <div className="section-inner shadow-sm rounded">
                        <h2 className="heading">Work Experience</h2>
                        <div className="content">
                            <div className="item">
                                <h3 className="title">Contract Developer <span className="place"><a href="https://codestaff.io">CodeStaff</a></span> <span className="year">(2018 - Present)</span></h3>
                                <p style={{fontWeight: '300'}}>Startup service for Developers and those who need Developers.</p>
                            </div>
                            <div className="item">
                                <h3 className="title">Software Engineer - <span className="place"><a href="/">Broadridge, El Dorado Hills, CA</a></span> <span className="year">(2016 - 2018)</span></h3>
                                <p style={{fontWeight: '300'}}>Development of fullstack Java/JSF2 database and reporting applications. Primefaces, Oracle Database, SQL</p>
                            </div>
                            
                            <div className="item">
                                <h3 className="title">Software Engineer - <span className="place"><a href="/">DST Output, El Dorado Hills, CA</a></span> <span className="year">(2001 - 2016)</span></h3>
                                <p style={{fontWeight: '300'}}>Development of Electronic Bill Presentment and Payment (EBPP) applications for fotune 500 companies</p>
                            </div>
                        </div>  
                    </div>                 
                </section>
            </div>{/*primary*/}
            <div className="secondary col-lg-4 col-12">
                 <aside className="info aside section">
                    <div className="section-inner shadow-sm rounded">
                        <h2 className="heading sr-only">Basic Information</h2>
                        <h2>witcraft (Noun)</h2>
                        <ol style={{listStyleType: 'decimal'}}>
                          <li>Art or skill of the mind; mental skill; contrivance; invention; the art of wit. </li>
                          <li>The art of reasoning; logic.</li>
                        </ol>                        
                    </div>                 
                </aside>{/*aside*/}
                <aside className="skills aside section">
                    <div className="section-inner shadow-sm rounded">
                        <h2 className="heading">Skills</h2>
                        <div className="content">
                            <p className="intro">
                                The primary challenge in a developer's career is to keep skills current without jumping on every new "bright and shiny".
                                When you love learning new things it's a lot easier. Some of these skills I developed by doing pro-bono work for underfunded startups.
                            </p>
                            
                            <div className="skillset">
                               
                                <div className="item">
                                    <h3 className="level-title">Web Development<span className="level-label" data-toggle="tooltip" data-placement="left" data-animation="true" title="Javascript &amp; NodeJs"><i className="fas fa-info-circle"></i>Pro</span></h3>
                                    <div className="level-bar">
                                        <div className="level-bar-inner" data-level="90%">
                                        </div>                                      
                                    </div>{/*level-bar*/}                                 
                                </div>

                                <div className="item">
                                    <h3 className="level-title">HTML5 &amp; CSS3 <span className="level-label" data-toggle="tooltip" data-placement="left" data-animation="true" title="You can use the tooltip to explain more about your skill level..."><i className="fas fa-info-circle"></i>Pro</span></h3>
                                    <div className="level-bar">
                                        <div className="level-bar-inner" data-level="90%">
                                        </div>                                      
                                    </div>{/*level-bar*/}                                 
                                </div>
                                                                
                                <div className="item">
                                    <h3 className="level-title">Java &amp; Unix<span className="level-label" data-toggle="tooltip" data-placement="left" data-animation="true" title="Server Applications and Data Transformation"><i className="fas fa-info-circle"></i>Expert</span></h3>
                                    <div className="level-bar">
                                        <div className="level-bar-inner" data-level="96%">
                                        </div>                                      
                                    </div>{/*level-bar*/}                                 
                                </div>

                                <div className="item">
                                    <h3 className="level-title">Relational Databases &amp; SQL<span className="level-label" data-toggle="tooltip" data-placement="left" data-animation="true" title="You can use the tooltip to explain more about your skill level..."><i className="fas fa-info-circle"></i>Pro</span></h3>
                                    <div className="level-bar">
                                        <div className="level-bar-inner" data-level="90%">
                                        </div>                                      
                                    </div>{/*level-bar*/}                                 
                                </div>

                                <div className="item">
                                    <h3 className="level-title">MongoDB &amp; GraphQL<span className="level-label" data-toggle="tooltip" data-placement="left" data-animation="true" title="You can use the tooltip to explain more about your skill level..."><i className="fas fa-info-circle"></i>Pro</span></h3>
                                    <div className="level-bar">
                                        <div className="level-bar-inner" data-level="86%">
                                        </div>                                      
                                    </div>{/*level-bar*/}                                 
                                </div>
                                <p><a className="more-link" href="https://www.linkedin.com/in/deanwitcraft/"><i className="fas fa-external-link-alt"></i>More on LinkedIn</a></p> 
                            </div>              
                        </div>  
                    </div>                 
                </aside>
                <aside className="testimonials aside section">
                    <div className="section-inner shadow-sm rounded">
                        <h2 className="heading">Testimonials</h2>
                        <div className="content">
                            <div className="item">
                                <blockquote className="quote">                                  
                                    <p style={{marginBottom: '1em'}}>Dean,</p>
                                    <p>Thank you for your time working on Insurabit. You have been great to work with a timely 
                                        and a wonderful addition to the startup adventure I had. You impressed me with your 
                                        technical knowledge and ability to learn new, related ideas. You are a valuable asset 
                                        to any team you're a part of. 
                                        <p>I highly recommend you work with Dean. He is incredible!</p>
                                    </p>
                                </blockquote>                
                                <p className="source"><span className="name">Doug Moeller</span><br /><span className="title">CEO InsuraBit</span></p>                                                             
                            </div>
                            <p><a className="more-link" href="https://www.linkedin.com/in/deanwitcraft/"><i className="fas fa-external-link-alt"></i>More on Linkedin</a></p> 
                            
                        </div>
                    </div>
                </aside>
            </div>
        </div>{/*row*/}
    </div>{/*masonry*/}
    
    {/* ******FOOTER****** */} 
    <footer className="footer">
        <div className="container text-center">
                {/* This template is free as long as you keep the attribution link below. Thank you for your support. :) If you'd like to use the template without the attribution, you can buy the commercial license via our website: themes.3rdwavemedia.com */}
                <small className="copyright">Designed with <i className="fas fa-heart"></i> by <a href="https://themes.3rdwavemedia.com" rel="noreferrer" target="_blank">Xiaoying Riley</a> for developers</small>
        </div>{/*container*/}
    </footer>{/*footer*/}
       
    </Layout>
    </>
  )
}

export default Index
